import React, {FC, useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import './GallerySlider.css';
import firebase from "firebase/compat";
import Slider from "react-slick";

const GallerySlider : FC<{ imageList: any[] }> = ({imageList}) => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        speed: 500,
        arrow:false,
        dots: false,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                }
            }
        ]
    };

    useEffect(() => {

        console.log('imgs',imageList)

    }, []);

    return (
        <Slider {...settings}>
            {Object.keys(imageList)
                .filter(imgK => imgK != "0").map(
                    (k, index) =>
                        /*                        <div
                                                    className={"relative event-item mr-4 w-4/5 h-60"}
                                                    style={{
                                                        backgroundImage: 'url(' + imageList[+k]?.immagine?.['_value' + lang] + ')',
                                                        backgroundPosition: 'center',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover',
                                                        borderRadius: '20px'
                                                    }}>
                                                </div>*/
        /*                <div className={'item-slider'} >
                            <h3 style={{color:'white'}}>{index}</h3>
                        </div>*/
                    <img className={'img-gallery'} src={imageList[+k]?.immagine?.['_value']}/>
                )
            }
        </Slider>
    );
}

export default GallerySlider;

