import React, { useContext, useEffect, useState } from "react";
import { AppContext, IApp } from "../AppContext";
import firebase from "../firebase";
import './Footer.css';
import {goTo} from "../Utils";

const Footer = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [footerState, setFooterState] = useState<any>({
    });

    useEffect(() => {
        firebase.database().ref(ctx.basePath + '/footer').on('value', res => {
            const footer = res.val();
            setFooterState(footer);
        })
    }, []);

    return (<>
            <div
                 className={'flex flex-col h-auto border-r-t-60 border-l-t-60 bg-dark-grey'}
                 style={{marginTop:"-60px"}}>


                <div className={'img-container-div bg-transparent'}>
                    <div
                        className={"relative event-item w-full h-100 border-l-t-60 border-r-t-60"}
                        style={{
                            backgroundImage: 'url(' +
                                footerState?.immagine?.['_value'] + ')',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}>
                    </div>
                </div>

                <div className={'flex flex-col md:flex-row p-12 gap-12'}>

                    <div className={'flex justify-start flex-1'}>
                        <p className={'font-s-48 font-w-400 text-color-white max-w-md'}
                           dangerouslySetInnerHTML={{__html: footerState?.contattaci?.['_value' + lang]}}>

                        </p>
                    </div>

                    <div className={'flex justify-start flex-col flex-1'}>
                        <p className={'font-s-16 font-w-400 text-color-white max-w-md'}
                           dangerouslySetInnerHTML={{__html: footerState?.sezione_contatti?.paragrafo_contatti?.['_value' + lang]}}>

                        </p>
                    </div>

                </div>

                <div className={'flex flex-col md:flex-row pl-12 pr-12 pb-12 gap-12 items-start md:items-end'}>

                    <div className={'flex justify-start font-s-16 font-w-400 text-white cursor-pointer flex-1 gap-4'}>
                        <p onClick={() => goTo(footerState?.privacy?.documento_privacy?.['_value' + lang])}>
                            {footerState?.privacy?.etichetta?.['_value' + lang]}
                        </p>
                        |
                        <p onClick={() => goTo(footerState?.cookies?.documento_cookies?.['_value' + lang])}>
                            {footerState?.cookies?.etichetta?.['_value' + lang]}
                        </p>
                        |
                        <p onClick={() => goTo(footerState?.terminicondizioni?.documento_termini_condizioni?.['_value' + lang])}>
                            {footerState?.terminicondizioni?.etichetta?.['_value' + lang]}
                        </p>
                    </div>


                    <div className={'flex justify-end flex-row font-s-16 font-w-400 text-white gap-4 flex-1'}>
                        {
                            footerState?.sezione_contatti?.social?._list ? Object.keys(footerState?.sezione_contatti?.social?._list)
                                .filter(imgK => imgK != "0").map(
                                    (k, index) =>
                                        <div className={'cursor-pointer'}
                                             onClick={() => goTo(footerState?.sezione_contatti?.social?._list[+k].link?.['_value' + lang])}>
                                            {footerState?.sezione_contatti?.social?._list[+k].titolo?.['_value' + lang]}
                                        </div>
                                ) : null
                        }
                    </div>

                </div>

            </div>
        </>
    )

}

export default Footer;

