import React, {useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import firebase from "../firebase";
import './Product.css';
import logo from '../assets/images/logo.png';
import AppButton from "../AppButton/AppButton";
import CollapsiblePanel from "../CollapsiblePanel/CollapsiblePanel";
import useCollapsiblePanel from "../CollapsiblePanel/useCollapsiblePanel";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {Parallax} from "react-scroll-parallax";

const Product = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [productState, setProductState] = useState<any>({});

    const [productSelectedState, setProductSelectedState] = useState<any>({});

    //Load data productState
    useEffect(() => {
        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/prodotto');
        dbRef.on('value', snapshot => {
            console.log('Product', snapshot.val())
            let val = snapshot.val();
            if (val?.sezione_struttura?.elenco_blocchi?._list)
                setProductSelectedState(
                    val.sezione_struttura.elenco_blocchi._list[Object.keys(val.sezione_struttura.elenco_blocchi._list)[1]]);
            setProductState(val);
        });

    }, []);

    const getYoutubeVideoID = (youtubeLink: string) => {
        const videoID = youtubeLink?.split('/').pop();
        return videoID;
    }

    const onProductChanged = (p) => {
        setProductSelectedState(p);
    }

    return (
        <div className={'bg-white'}>

            <div className={'bg-mid-grey pb-48'}>

                <div className={'flex flex-wrap pt-24 md:pt-20 pb-20 gap-4 three-images-container-div'}>
                    {
                        productState?.intro?.gallery?._list ? Object.keys(productState?.intro?.gallery?._list)
                            .filter(imgK => imgK != "0").map(
                                (k, index) =>
                                    <Parallax key={'im_' + k}
                                              translateY={index == 0 ? [100, -100] : (index == 1 ? [100, -100] : [100, -100])}
                                              translateX={index == 0 ? [32, -30] : (index == 1 ? [0, 0] : [-30, 30])}>
                                        <img
                                            className={'w-auto h-auto border-r-20 ' + (index == 1 ? ' max-h-96 ' : ' max-h-50-vmin min-h-30-vmin')}
                                            src={productState?.intro?.gallery?._list[+k]?.immagine?.['_value']}/>
                                    </Parallax>
                            ) : null
                    }

                </div>

                <div className={'flex justify-center md:pt-4'} style={{marginTop: "-5vmin"}}>
                    <img src={logo} className={'max-w-40 h-auto'}/>
                </div>

                <div className={'flex justify-center pt-16 pb-16'}>
                    <p className={'font-s-48 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: productState?.intro?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center pb-16'}>
                    <p className={'font-s-20 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: productState?.intro?.testo_secondario?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center pl-12 pr-12'}>
                    {
                        productState?.intro?.video?._mediaType?.startsWith('youtube') ?
                            <iframe className={'video-container-div extra-height-video video-home max-w-4xl rounded-3xl'}
                                    allow="autoplay"
                                    src={productState?.intro?.video?.['_value'] +
                                        '?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=0&playlist=' +
                                        getYoutubeVideoID(productState?.intro?.video?.['_value'])}>
                            </iframe> :
                            (productState?.intro?.video ?
                                <Parallax scale={[0.7, 1, 'easeInOutQuint']}>
                                    <video
                                        className={'video-container-div extra-height-video video-home max-w-5xl b-r-20 rounded-3xl'}
                                        loop controls={true}>
                                        <source src={productState?.intro?.video?.['_value']}
                                                type={productState?.intro?.video?._mediaType}/>
                                        Your browser does not support the video tag.
                                    </video>
                                </Parallax>
                                : null)
                    }
                </div>

            </div>

            <div className={'bg-light-purple pb-48'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-light-purple text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {productState?.sezione_struttura?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex justify-center pt-32 pb-16 h-full'}>
                    <p className={'font-s-36 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12 h-full'}
                       dangerouslySetInnerHTML={{__html: productState?.sezione_struttura?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex flex-col md:flex-row justify-center pr-12 pl-12 pb-12 product-struct-box-div'}>

                    <div className={'flex flex-col md:flex-row justify-center gap-6 h-full'}>

                        <div
                            className={'flex-2 h-full overflow-auto bg-white flex items-center' +
                                ' border-r-20'}>

                            <div className={'h-auto flex flex-col gap-8 p-6'}>
                                <div className={'flex-1 flex justify-center items-start min-h-0'}>
                                    <img className={'h-16 md:h-20 w-16 md:w-20'}
                                         src={productSelectedState?.immagine_testo?.['_value']}/>
                                </div>
                                <div className={'flex-1 flex justify-center items-start text-center min-h-0'}>
                                    <p className={'font-s-36 font-w-600'}>{productSelectedState?.titolo?.['_value' + lang]}</p>
                                </div>
                                <div className={'flex-5 min-h-0'}>
                                    <p className={'font-s-20 font-w-400 md:ml-12 md:mr-12 text-center max-w-6xl'}
                                       dangerouslySetInnerHTML={{__html: productSelectedState?.testo?.['_value' + lang]}}>
                                    </p>
                                </div>
                            </div>

                        </div>


                        {/*Image*/}
                        <div className={'img-container-div flex-1 border-r-20 h-full hidden lg:flex'}>
                            <div
                                data-aos="fade"
                                data-aos-once="true"
                                className={"relative event-item w-full h-full border-r-20"}
                                style={{
                                    backgroundImage: 'url(' +
                                        productSelectedState?.immagine?.['_value'] + ')',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    minHeight: '50vmin'
                                }}>
                            </div>
                        </div>


                    </div>

                </div>

                <div className={'flex justify-center gap-2'}>

                    {
                        productState?.sezione_struttura?.elenco_blocchi?._list ?
                            Object.keys(productState?.sezione_struttura?.elenco_blocchi?._list)
                                .filter(k => k != "0").map(k =>
                                <div className={'w-16 h-1 bg-white border-r-20 cursor-pointer ' +
                                    (productState?.sezione_struttura?.elenco_blocchi?._list[+k]?._id == productSelectedState?._id
                                        ? 'bg-dark-grey' : '')}
                                     onClick={() => onProductChanged(productState?.sezione_struttura?.elenco_blocchi?._list[+k])}>
                                </div>
                            ) : null
                    }

                </div>

                <div className={'flex justify-center pt-32 pb-32'}>
                    <p className={'font-s-36 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: productState?.sezione_struttura?.testo_video?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center pl-12 pr-12'}>
                    {
                        productState?.sezione_struttura?.video?._mediaType?.startsWith('youtube') ?
                            <iframe className={'video-container-div extra-height-video video-home max-w-4xl rounded-3xl'}
                                    allow="autoplay"
                                    src={productState?.sezione_struttura?.video?.['_value'] +
                                        '?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=0&playlist=' +
                                        getYoutubeVideoID(productState?.sezione_struttura?.video?.['_value'])}>
                            </iframe> :
                            (productState?.sezione_struttura?.video ?
                                <video
                                    className={'video-container-div extra-height-video video-home max-w-5xl b-r-20 rounded-3xl'}
                                    style={{maxWidth: '52rem'}}
                                    loop controls={true}>
                                    <source src={productState?.sezione_struttura?.video?.['_value']}
                                            type={productState?.sezione_struttura?.video?._mediaType}/>
                                    Your browser does not support the video tag.
                                </video>
                                : null)
                    }
                </div>

                <AppButton link={productState?.sezione_struttura?.bottone?.link['_value' + lang]}
                           label={productState?.sezione_struttura?.bottone?.etichetta?.['_value' + lang]}></AppButton>

            </div>

            <div className={'bg-white pb-64 flex flex-col items-center'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-white text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                    {productState?.sezione_descrizione?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex items-center h-50-vmin w-full max-w-5xl mt-32 mb-16 pl-12 pr-12 md:pl-0 md:pr-0'}>
                    <div
                        data-aos="fade"
                        data-aos-once="true"
                        className={"relative event-item w-full h-full border-r-20"}
                        style={{
                            backgroundImage: 'url(' +
                                productState?.sezione_descrizione?.immagine?.['_value'] + ')',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}>
                    </div>
                </div>

                <div className={'flex flex-col items-center max-w-screen-lg w-full pl-12 pr-12'}>

                    {
                        productState?.sezione_descrizione?.elenco_blocchi?._list ?
                            Object.keys(productState?.sezione_descrizione?.elenco_blocchi?._list)
                                .filter(k => k != "0").map(k =>
                                <Accordion key={'coll_' + k}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        <p className={'font-s-36 font-w-600'}>
                                            {productState?.sezione_descrizione?.elenco_blocchi?._list[+k]?.titolo?.['_value' + lang]}
                                        </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className={'font-s-20 font-w-400 pb-4'} dangerouslySetInnerHTML={{
                                            __html:
                                                productState?.sezione_descrizione?.elenco_blocchi?._list[+k]?.testo?.['_value' + lang]
                                        }}>

                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            ) : null
                    }

                    <div className={'flex justify-center pt-32 pb-32'}>
                        <p className={'font-s-36 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                           dangerouslySetInnerHTML={{__html: productState?.sezione_descrizione?.testo_video?.['_value' + lang]}}>

                        </p>
                    </div>

                    <div className={'flex justify-center w-full'}>
                        {
                            productState?.sezione_descrizione?.video?._mediaType?.startsWith('youtube') ?
                                <iframe className={'video-container-div extra-height-video video-home max-w-4xl rounded-3xl'}
                                        allow="autoplay"
                                        src={productState?.sezione_descrizione?.video?.['_value'] +
                                            '?controls=0&showinfo=0&rel=0&autoplay=0&loop=1&mute=0&playlist=' +
                                            getYoutubeVideoID(productState?.sezione_descrizione?.video?.['_value'])}>
                                </iframe> :
                                (productState?.sezione_descrizione?.video ?
                                    <video
                                        className={'video-container-div extra-height-video video-home max-w-5xl b-r-20 rounded-3xl'}
                                        style={{maxWidth: '52rem'}}
                                        loop controls={true}>
                                        <source src={productState?.sezione_descrizione?.video?.['_value']}
                                                type={productState?.sezione_descrizione?.video?._mediaType}/>
                                        Your browser does not support the video tag.
                                    </video>
                                    : null)
                        }
                    </div>


                    <AppButton link={productState?.sezione_descrizione?.bottone?.link['_value' + lang]}
                               label={productState?.sezione_descrizione?.bottone?.etichetta?.['_value' + lang]}></AppButton>

                </div>

            </div>


        </div>
    );
}

export default Product;

