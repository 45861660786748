import React, {useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import firebase from "../firebase";
import './Home.css';
import {ImagesSlider} from "../ui/images-slider/ImagesSlider";
import plus from '../assets/images/plus.svg';
import {goTo} from "../Utils";
import ScrollContainer from "react-indiana-drag-scroll";
import FiveItemsGallery from "../FiveItemsGallery/FiveItemsGallery";
import AppButton from "../AppButton/AppButton";
import GallerySlider from "../GallerySlider/GallerySlider";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";

const Home = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [homeState, setHomeState] = useState<any>({});

    //Load data homeState
    useEffect(() => {
        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/home');
        dbRef.on('value', snapshot => {
            console.log(snapshot.val())
            setHomeState(snapshot.val());
        });

    }, []);

    const getYoutubeVideoID = (youtubeLink: string) => {
        const videoID = youtubeLink?.split('/').pop();
        return videoID;
    }

    return (
        <div className={'bg-white'}>

            <div>
                {
                    homeState?.intro?._mediaType.startsWith('youtube') ?
                        <iframe className={'video-container-div video-home'}
                                data-aos="fade-in"
                                data-aos-once="true"
                                allow="autoplay"
                                src={homeState?.intro?.['_value' + lang] +
                                    '?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=' +
                                    getYoutubeVideoID(homeState?.intro?.['_value'])}>
                        </iframe> :
                        (homeState?.intro ?
                            <video className={'video-container-div video-home'}
                                   data-aos="fade-in"
                                   data-aos-once="true" autoPlay loop muted playsInline>
                                <source src={homeState?.intro?.['_value']}
                                        type={homeState?.intro?._mediaType}/>
                                Your browser does not support the video tag.
                            </video> : null)
                }
            </div>

            <div className={'bg-dark-grey pb-64'}>

                <div className={'flex justify-center pt-32 pb-16'}>
                    <p className={'font-s-16 font-w-300 text-color-white'}>
                        {homeState?.titolo?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex justify-center pb-32'}>
                    <p className={'font-s-96-italic w-3/4 text-center text-color-white'}
                       dangerouslySetInnerHTML={{__html: homeState?.testo_intro?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center h-50-vmin mb-36 md:mb-20'}>
                    {
                        homeState?.gallery_intro?._list ?
                            <div className={"h-full w-full md:h-80"} style={{maxWidth:'2000px'}}>
                                <GallerySlider imageList={homeState?.gallery_intro?._list}></GallerySlider>
                            </div> : null
                    }
                </div>

            </div>

            <div className={'bg-mid-grey pb-48'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-mid-grey text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {homeState?.sezione_prodotto?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex justify-center pt-32 pb-16'}>
                    <p className={'font-s-48 font-w-400 text-color-dark-grey max-w-4xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: homeState?.sezione_prodotto?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center pb-20 md:pb-40'}>
                    <p className={'font-s-20 font-w-400 text-color-dark-grey max-w-4xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: homeState?.sezione_prodotto?.testo_secondario?.['_value' + lang]}}>

                    </p>
                </div>

                <FiveItemsGallery imageList={homeState?.sezione_prodotto?.gallery?._list}></FiveItemsGallery>


                <div className={'flex justify-center pt-32 pb-16'}>
                    <p className={'font-s-48 font-w-400 text-color-dark-grey max-w-4xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: homeState?.sezione_prodotto?.testo_dettaglio?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center pb-16'}>
                    <p className={'font-s-20 font-w-400 text-color-dark-grey max-w-4xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: homeState?.sezione_prodotto?.testo_secondario_dettaglio?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex items-center h-50-vmin w-full justify-center mt-16 mb-16 pl-12 pr-12 md:pl-0 md:pr-0'}>
                    <div
                        data-aos="fade"
                        data-aos-once="true"
                        className={"relative event-item w-full max-w-5xl md:w-4/5 h-50-vmin"}
                        style={{
                            backgroundImage: 'url(' + homeState?.sezione_prodotto?.immagine_prodotto?.['_value'] + ')',
                            backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                            borderRadius: '300px'
                        }}>
                    </div>
                </div>

                <AppButton link={homeState?.sezione_prodotto?.bottone?.link['_value' + lang]}
                           label={homeState?.sezione_prodotto?.bottone?.etichetta?.['_value' + lang]}></AppButton>

            </div>

            {/*Business*/}
            <div className={'bg-white pb-48'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-white text-color-purple h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 font-s-16 font-w-400 app-label'}>
                        {homeState?.sezione_business?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                {/* business list */}
                <div className={'imgtext-container-div flex flex-col gap-12 md:gap-24 pt-32'}>
                    {homeState?.sezione_business?.elenco_blocchi?._list ? Object.keys(homeState?.sezione_business?.elenco_blocchi?._list)
                        .filter(imgK => imgK != "0").map(
                            (k, index) =>
                                <div className={'imgtext-div flex flex-col md:flex-row'} key={'imgtext_' + index}>

                                    {/*Text action*/}
                                    <div className={'flex-1 flex items-center'}>

                                        <div className={'flex flex-col gap-4 max-w-2xl'}>
                                            <div className={'desc-div'}>
                                                <p className={'font-s-36 font-w-600'}>
                                                    {homeState?.sezione_business?.elenco_blocchi?._list[+k].titolo?.['_value' + lang]}
                                                </p>
                                            </div>

                                            <div className={'desc-div'}>
                                                <p className={'font-s-20 font-w-400'}>
                                                    {homeState?.sezione_business?.elenco_blocchi?._list[+k].testo?.['_value' + lang]}
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                    {/*Image*/}
                                    <div className={'img-container-div'}>
                                        <div
                                            data-aos="fade"
                                            data-aos-once="true"
                                            className={"relative event-item w-full h-100 border-r-20"}
                                            style={{
                                                backgroundImage: 'url(' +
                                                    homeState?.sezione_business?.elenco_blocchi?._list[+k]?.immagine?.['_value'] + ')',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                            }}>
                                        </div>
                                    </div>

                                </div>
                        ) : ""}
                </div>

            </div>

            {/*Servizi*/}
            <div className={'bg-dark-grey pb-48'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-dark-grey text-white h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {homeState?.sezione_servizi?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex justify-center pt-32 pb-16'}>
                    <p className={'font-s-48 font-w-400 text-white max-w-4xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: homeState?.sezione_servizi?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center'}>
                    <p className={'font-s-20 font-w-400 text-white max-w-4xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: homeState?.sezione_servizi?.testo_secondario?.['_value' + lang]}}>

                    </p>
                </div>

                <ScrollContainer className="app-slider pt-32">
                    {Object.keys(homeState?.sezione_servizi?.elenco_servizi?._list || {})
                        .filter((key: string) => homeState?.sezione_servizi?.elenco_servizi?._list[key as any]?._id != 0)
                        ?.map((k: string) => homeState?.sezione_servizi?.elenco_servizi?._list[k as any])
                        ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1).map((item: any, index: number) => (
                                <div className={'rectangle-div bg-white'} key={'service_' + index}>

                                    <div className={'flex justify-center'}>
                                        <img className={'h-16 md:h-20 w-16 md:w-20'}
                                             src={item?.immagine?.['_value']}/>
                                    </div>

                                    <div className={'font-s-24 font-w-600 flex justify-center text-center'}>
                                        {item?.titolo?.['_value' + lang]}
                                    </div>

                                    <div className={'font-s-20 font-w-400 flex justify-center text-center'}>
                                        {item?.sottotitolo?.['_value' + lang]}
                                    </div>


                                </div>
                            )
                        )
                    }
                </ScrollContainer>


            </div>

            {/*Review*/}
            <div className={'bg-mid-grey pb-64'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-mid-grey text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {homeState?.sezione_review?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <ScrollContainer className="app-slider pt-32">
                    {Object.keys(homeState?.sezione_review?.elenco_review?._list || {})
                        .filter((key: string) => homeState?.sezione_review?.elenco_review?._list[key as any]?._id != 0)
                        ?.map((k: string) => homeState?.sezione_review?.elenco_review?._list[k as any])
                        ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1).map((item: any, index: number) => (
                                <div className={'rectangle-div rectangle-small-div bg-white overflow-hidden'}
                                     key={'service_' + index}>

                                    <div className={'flex justify-center w-4/5'}>
                                        <img className={'h-16 md:h-20 w-16 md:w-20'}
                                             src={item?.immagine?.['_value']}/>
                                    </div>

                                    <div className={'flex flex-col w-4/5 text-center items-center'}>

                                        <div
                                            className={'flex-1 font-s-14 font-w-500 flex justify-center text-center items-end'}>
                                            {item?.nome?.['_value' + lang]}
                                        </div>

                                        <div
                                            className={'flex-1 font-s-14 font-w-400 flex justify-center text-center text-color-mid-grey'}>
                                            {item?.luogo?.['_value' + lang]}
                                        </div>

                                    </div>

                                    <div
                                         className={'font-s-14 font-w-400 flex justify-center text-center overflow-ellipsis'}>
                                        <p className={'font-s-14 font-w-400 justify-center overflow-auto'}
                                           dangerouslySetInnerHTML={{__html: item?.testo?.['_value' + lang]}}>

                                        </p>
                                    </div>


                                </div>
                            )
                        )
                    }
                </ScrollContainer>


            </div>

        </div>
    );
}

export default Home;

