import React, { useContext, useEffect, useState } from "react";
import { AppContext, IApp } from "../AppContext";
import firebase from "../firebase";
import './PrivacyView.css';

const PrivacyView = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [privacyState, setPrivacyState] = useState<any>({});

    //Load data homeState
    useEffect(() => {

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/privacy');
        dbRef.on('value', snapshot => {
            setPrivacyState(snapshot.val());
        });

    }, []);




    return (
        <div className={'bg-white pl-4 pr-4 pt-2 pb-2 md:pr-6 md:pl-6'}>
            <div dangerouslySetInnerHTML={{__html:privacyState?.testo?.['_value'+lang]}} >

            </div>
        </div>
    );

}

export default PrivacyView;