import React, {useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import firebase from "../firebase";
import './AboutView.css';
import {goTo} from "../Utils";


const AboutView = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [aboutState, setAboutState] = useState<any>({});

    //Load data homeState
    useEffect(() => {

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/chi_siamo');
        dbRef.on('value', snapshot => {
            setAboutState(snapshot.val());
        });

    }, []);


    return (
        <div className={'bg-white pb-64'}>

            <div className={'bg-white pb-16'}>


                <div className={'flex justify-center pb-16 pt-32'}>
                    <p className={'font-s-48 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: aboutState?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center'}>
                    <p className={'font-s-20 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: aboutState?.testo_secondario?.['_value' + lang]}}>

                    </p>
                </div>

            </div>

            <div
                className={'flex flex-col relative justify-center md:flex-row md:gap-16 cards-container md:pl-12 md:pr-12'}>

                {
                    aboutState?.team?.cards?._list ?
                        Object.keys(aboutState?.team?.cards?._list)
                            .filter(k => k != "0").map(k =>
                            <div
                                className={'flex flex-col gap-2 w-full items-start md:items-center pl-12 pr-12 md:pr-0 md:pl-0'}>

                                <div
                                    className={'flex md:items-center h-50-vmin w-full max-w-5xl mt-16 mb-16 pl-0 pr-0'}>
                                    <div
                                        className={"relative event-item w-full h-full border-r-20"}
                                        style={{
                                            backgroundImage: 'url(' +
                                                aboutState?.team?.cards?._list[+k]?.immagine_card?.['_value'] + ')',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            borderRadius: "20px"
                                        }}>
                                    </div>
                                </div>

                                <p className={'font-s-36 font-w-600 md:w-2/3'}>
                                    {aboutState?.team?.cards?._list[+k]?.nome?.['_value' + lang]}
                                </p>
                                <p className={'font-s-20 font-w-400 pb-4 md:w-2/3'} dangerouslySetInnerHTML={{
                                    __html:
                                        aboutState?.team?.cards?._list[+k]?.ruolo?.['_value' + lang]
                                }}>
                                </p>
                                <div className={'flex font-s-16 font-w-400 pb-4 md:w-2/3 gap-3'}>
                                    <span>Curriculum vitae</span>
                                    <span className={'cursor-pointer'}
                                          onClick={() => goTo(aboutState?.team?.cards?._list[+k]?.cv_ita?.['_value' + lang])}>
                                        Italiano
                                    </span>

                                    {

                                        aboutState?.team?.cards?._list[+k]?.cv_en?.['_value' + lang] ?

                                            <>
                                                <span> | </span>

                                                <span className={'cursor-pointer'}
                                                      onClick={() => goTo(aboutState?.team?.cards?._list[+k]?.cv_en?.['_value' + lang])}>
                                                    English
                                                </span>
                                            </>

                                            :

                                            null

                                    }
                                </div>
                            </div>
                        ) : null
                }


            </div>

        </div>
    );

}

export default AboutView;
