import {motion, useScroll, useTransform, useViewportScroll} from "framer-motion";
import React, {FC, useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import './FiveItemsGallery.css';
import {Parallax} from "react-scroll-parallax";

const FiveItemsGallery: FC<{ imageList: any[] }> = ({imageList}) => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [homeState, setHomeState] = useState<any>({});

    useEffect(() => {



    }, [imageList]);

    const {scrollY} = useScroll();
    const y2 = useTransform(scrollY, [0, 300], [0, -100]);


    return (
        <div className={'flex five-items-container-div gap-3 pb-20 md:pb-40'}>
            {
                imageList ? Object.keys(imageList)
                    .filter(imgK => imgK != "0").map(
                        (k, index) =>

                            <Parallax key={'im_' + k} translateX={[0, -100]} >

                                <img className={'w-full h-auto '}
                                     src={imageList[+k]?.immagine?.['_value']}/>

                            </Parallax>
                    ) : null
            }

        </div>
    );
}

export default FiveItemsGallery;

