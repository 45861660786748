import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import Main from "./Main"
import { AppContext, IApp } from "./AppContext";
import firebase from "firebase/compat/app";
import AOS from 'aos';
import "aos/dist/aos.css";
import { CircularProgress } from "@mui/material";
// @ts-ignore
import DOMPurify from 'dompurify';


const App = () => {
    const [isDraftEnabled, setIsDraftEnabled] = useState(false);
    const [render, setRender] = useState(false);
    const [style, setStyle] = useState({style: '', theme: ''});

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;

    useEffect(() => {
        AOS.init({
            // initialise with other settings
            duration: 1000
        });

        firebase
            .database()
            .ref(ctx.basePath + "/impostazioni/css")
            .on("value",
                (res) => {
                    if (res.val()) {
                        const css = res.val();
                        setStyle({
                            style: css.style?._value,
                            theme: css.theme?._value
                        })
                    }
                });

        //Set language for app
        ctx.languageCode = localStorage.getItem("lang") ?? "";

        if (ctx.draft) {
            firebase.database().ref(ctx.basePath + "/projectApiKey").once('value').then(res => {
                if (res.val() === ctx.projectApiKey) {
                    console.log('modalità db draft valida')
                    setIsDraftEnabled(true);
                    setRender(true);
                } else {
                    console.log('modalità db draft non valida')
                    setIsDraftEnabled(false);
                    setRender(true);
                }
            })
        } else {
            console.log('modalità db produzione')
            setIsDraftEnabled(false);
            setRender(true);
        }

        // setTimeout(() => {
        //     Scrollbar.init(document.querySelector('#ristoflixSite') as HTMLElement, {
        //         damping: 0.08,
        //         thumbMinSize: 20,
        //         alwaysShowTracks: false
        //     }); 
        // }, 500);
    }, []);

    return (
        <>
            <head>
                <style dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(style?.theme)
                }}>

                </style>
                <style dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(style?.style)
                }}>
                </style>
            </head>

            <div className={'app-div'}>
                {
                    render && (!ctx.draft || isDraftEnabled) ?
                        <Main></Main>
                        :
                        render && (!isDraftEnabled) ?
                            <NotAvailPreview></NotAvailPreview>
                            :
                            <CircularProgress className={'progress-center'} />
                }
            </div>
        </>
    )



}

export default App;

const NotAvailPreview = () => {
    return <div className={'not-avail-preview-div'}>
        <p className={'text-align-c'}>Anteprima non disponibile per questo progetto</p>
    </div>
}

