import React, { FC, useContext, useEffect, useState } from 'react';
import './LanguagesView.css';
import firebase from "../firebase";
import { AppContext, IApp } from "../AppContext";
import { Language } from './Language';


const LanguagesView = () => {
    const [languages, setLanguages] = useState<Language[]>([]);
    const [stateSelectionLang, setStateSelectionLang] = useState<boolean>(false);
    const [currentDescrLanguage, setDescriptionLanguage] = useState<string>('');

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;

    useEffect(() => {
        loadLanguages();
    }, []);

    const loadLanguages = () => {
        const lang = localStorage.getItem("lang") ?? "";
        firebase
            .database()
            .ref(ctx.basePath + "/impostazioni/lingue")
            .get().then(
                (res) => {

                    if (res.val() && res.val()?._list) {
                        const languages: Language[] = [].concat(Object.values(res.val()?._list))?.filter((l: any) => l._id != 0);
                        if (languages && languages.length > 0) {

                            //Set list of lang
                            setLanguages(languages);

                            //Set current if already exist in local storage
                            let currentLang = languages.find((l: any) => l.codice?.['_value'] == (lang == 'undefined' ? "" : lang));
                            if (currentLang)
                                setCurrentLanguage(currentLang);
                        }
                    }
                });
    }

    const setCurrentLanguage = (language: Language) => {
        let obj: Language = {
            codice: language.codice['_value'],
            descrizione: language.descrizione['_value']
        }
        ctx.languageCode = obj.codice;
        let oldLang=localStorage.getItem('lang');
        localStorage.setItem('lang', ctx.languageCode);
        setDescriptionLanguage(obj.descrizione);

        setStateSelectionLang(false);
        if(oldLang!=ctx.languageCode)
            window.location.reload();
    }

    const toggleSelectionLanguage = (state: boolean) => {
        setStateSelectionLang(state);
    }

    return (
        <>
            {
                languages && Object.keys(languages)?.length > 0 ? <>
                    {/*lingua mobile*/}
                    <div className={'flex items-center md:hidden cursor-pointer'}>
                        {Object.keys(languages).map((k, index) =>
                            <label className={"capitalize font-s-12 text-color-mid-grey pr-2 border-r border-color-third" + (index != 0 ? " pl-2" : "") + ((index === (languages.length - 1)) ? " border-r-0" : "") + (ctx.languageCode === languages[k].codice._value ? " font-w-600" : " font-w-400")}
                                key={'lang_' + languages[k].codice._value} onClick={() => setCurrentLanguage(languages[k])}>
                                {languages[k].descrizione._value}
                            </label>
                        )}
                    </div>
                    {/*lingua desktop*/}
                    <div className={'hidden items-center md:flex cursor-pointer'}>
                        <div className="relative flex items-center flex-col title-extra-small min-w-24">
                            <span className="flex items-center w-full" onClick={() => toggleSelectionLanguage(!stateSelectionLang)}>
                                <label className="material-symbols-outlined text-color-mid-grey">expand_more</label>
                                <label className="text-color-mid-grey flex-1 text-right">{currentDescrLanguage}</label>
                            </span>

                            <div className={'absolute top-6 left-0 flex flex-col bg-white items-center w-full overflow-hidden bs-transition-3s rounded ' +
                                (stateSelectionLang ? "bg-purple" : "max-h-0")}>
                                {Object.keys(languages).map((k, index) =>
                                    <label className={"capitalize title-extra-small pt-4 pl-4 pr-4 w-full " + ((index == (languages.length -1)) ? " pb-4" :"") + (ctx.languageCode === languages[k].codice._value ? " opacity-50 text-white" : " text-white cursor-pointer")}
                                        key={'lang_' + languages[k].codice._value} onClick={() => setCurrentLanguage(languages[k])}>
                                        {languages[k].descrizione._value}
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>
                </>
                    :
                    null
            }
        </>
    );


}






export default LanguagesView;
