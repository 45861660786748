import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AppContext, IApp} from "../AppContext";
import firebase from "../firebase";
import './EventsView.css';
import {goTo} from "../Utils";
import downloadIcon from '../assets/images/download.svg'

const EventsView = () => {
    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;
    let params = useParams();

    const [eventsState, setEventsState] = useState<any>({});

    //Load data homeState
    useEffect(() => {
        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/eventi');
        dbRef.on('value', snapshot => {
            setEventsState(snapshot.val());
        });


    }, []);


    return (
        <div className={'bg-white w-full flex flex-col pb-64 justify-center items-center'}>

            <div className={'flex w-full md:w-2/3 pl-16 pb-32 pt-32 text-center pr-16 justify-center'}>
                <p className={'font-s-48 font-w-400 text-color-dark-grey text-left md:text-center md:max-w-3xl md:mr-12'}
                   dangerouslySetInnerHTML={{__html: eventsState?.testo?.['_value' + lang]}}>

                </p>
            </div>

            <div className={'flex w-full md:w-2/3 flex-col items-start pl-16 pr-16 pb-16'}>

                <p className={'font-s-36 font-w-600 text-color-purple'}>{eventsState?.etichetta_eventi?.['_value' + lang]}</p>

                {
                    eventsState?.elenco_eventi?._list ?
                        Object.keys(eventsState?.elenco_eventi?._list)
                            .filter(k => k != "0").map(k =>
                            <div className={'flex flex-col gap-6 border-b border-color-purple w-full'}>
                                <p className={'font-s-36 font-w-600 pt-8'}>
                                    {eventsState?.elenco_eventi?._list[+k]?.titolo?.['_value' + lang]}
                                </p>
                                <p className={'font-s-20 font-w-400 pb-8'} dangerouslySetInnerHTML={{
                                    __html:
                                        eventsState?.elenco_eventi?._list[+k]?.descrizione?.['_value' + lang]
                                }}>
                                </p>
                                {
                                    eventsState?.elenco_eventi?._list[+k]?.etichetta_link?.['_value' + lang] ?
                                        <p className={'font-s-20 font-w-400 pb-6 cursor-pointer flex items-center gap-2'}
                                           onClick={() => goTo(eventsState?.elenco_eventi?._list[+k]?.link?.['_value' + lang])}>
                                            <span className="material-symbols-outlined text-color-dark-grey">
                                                arrow_forward
                                            </span>
                                            <span className={'font-s-20 font-w-400'}>
                                                {eventsState?.elenco_eventi?._list[+k]?.etichetta_link?.['_value' + lang]}
                                            </span>
                                        </p> : null
                                }
                            </div>
                        ) : null
                }

            </div>

            <div className={'flex w-full md:w-2/3 flex-col items-start pl-16 pr-16 pt-16'}>

                <p className={'font-s-36 font-w-600 text-color-purple'}>{eventsState?.etichetta_schede?.['_value' + lang]}</p>

                {
                    eventsState?.elenco_schede?._list ?
                        Object.keys(eventsState?.elenco_schede?._list)
                            .filter(k => k != "0").map(k =>
                            <div className={'flex flex-col gap-6 border-b border-color-purple w-full cursor-pointer'}
                                 onClick={() => goTo(eventsState?.elenco_schede?._list[+k]?.documento?.['_value' + lang])}>
                                <div className={'font-s-36 font-w-600 pt-8 flex flex-row items-center gap-4'}>
                                    <p className={'flex-05'}>
                                        <img className={'h-7 w-7'}
                                             src={downloadIcon}/>
                                    </p>
                                    <p className={'flex-9'}>
                                    <span>
                                        {eventsState?.elenco_schede?._list[+k]?.titolo?.['_value' + lang]}
                                    </span>
                                    </p>
                                </div>
                                <div className={'pb-8 flex flex-row items-center gap-4'}>
                                    <p className={'flex-05'}>
                                        <span className={'h-7 w-7'}></span>
                                    </p>
                                    <p className={'flex-9'}>
                                        <span className={'font-s-20 font-w-400'} dangerouslySetInnerHTML={{
                                            __html:
                                                eventsState?.elenco_schede?._list[+k]?.descrizione?.['_value' + lang]
                                        }}></span>
                                    </p>
                                </div>
                            </div>
                        ) : null
                }

            </div>

        </div>
    );
}

export default EventsView;
