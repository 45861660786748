// context/todoContext.tsx
import * as React from 'react';
import { Language } from './Languages/Language';

export interface IApp {
    basePath: string;
    draft: boolean;
    projectApiKey: string | null;
    languageCode?: string | '';
}

export type AppContextType = {
    appContext: IApp;
    updateAppContext?: (basePath: string) => void;
};

export const AppContext =
    React.createContext<AppContextType>({ appContext: { basePath: '', draft: false, projectApiKey: '', languageCode: '' } });

const AppProvider: React.FC<React.ReactNode> = () => {
    const [appContext] = React.useState<IApp>({
        basePath: "",
        draft: false,
        projectApiKey: null,
        languageCode: ''
    });
    const updateAppContext = (basePath: string) => {
        appContext.basePath = basePath;
    };
    return <AppContext.Provider value={{ appContext, updateAppContext }}> </AppContext.Provider>;
};

export default AppProvider;
