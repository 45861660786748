import React, {useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import firebase from "../firebase";
import './Application.css';
import {ImagesSlider} from "../ui/images-slider/ImagesSlider";
import AppButton from "../AppButton/AppButton";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import GallerySlider from "../GallerySlider/GallerySlider";

const Application = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [applicationState, setApplicationState] = useState<any>({});


    //Load data applicationState
    useEffect(() => {
        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/app');
        dbRef.on('value', snapshot => {
            console.log('Product', snapshot.val())
            let val = snapshot.val();
            setApplicationState(val);
        });

    }, []);

    return (
        <div className={'bg-white'}>

            <div className={'flex justify-center h-96 w-full'}>
                <div
                    data-aos="fade"
                    data-aos-once="true"
                    className={"relative event-item w-full h-full"}
                    style={{
                        backgroundImage: 'url(' + applicationState?.immagine_intro?.['_value'] + ')',
                        backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
                    }}>
                </div>
            </div>

            <div className={'bg-white pb-64'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-white text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {applicationState?.sezione_app?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex justify-center pt-32 pb-32'}>
                    <p className={'font-s-36 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: applicationState?.sezione_app?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center h-50-vmin mb-16'}>
                    {
                        applicationState?.sezione_app?.gallery?._list ?
                            <div className={"h-full w-full md:h-80"} style={{maxWidth: '2000px'}}>
                                <GallerySlider
                                    imageList={applicationState?.sezione_app?.gallery?._list}></GallerySlider>
                            </div> : null
                    }
                </div>


            </div>

            <div className={'bg-light-purple pb-64 flex flex-col items-center'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-light-purple text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {applicationState?.sezione_funzioni?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex items-center h-50-vmin w-full max-w-5xl mt-32 mb-16 pl-12 pr-12 md:pl-0 md:pr-0'}>
                    <div
                        data-aos="fade"
                        data-aos-once="true"
                        className={"relative event-item w-full h-full border-r-20"}
                        style={{
                            backgroundImage: 'url(' +
                                applicationState?.sezione_funzioni?.immagine?.['_value'] + ')',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}>
                    </div>
                </div>

                <div className={'flex flex-col items-center max-w-screen-lg w-full pl-12 pr-12'}>

                    {
                        applicationState?.sezione_funzioni?.elenco_blocchi?._list ?
                            Object.keys(applicationState?.sezione_funzioni?.elenco_blocchi?._list)
                                .filter(k => k != "0").map(k =>
                                <Accordion key={'coll_' + k}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        <p className={'font-s-36 font-w-600'}>
                                            {applicationState?.sezione_funzioni?.elenco_blocchi?._list[+k]?.titolo?.['_value' + lang]}
                                        </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className={'font-s-20 font-w-400 pb-4'} dangerouslySetInnerHTML={{
                                            __html:
                                                applicationState?.sezione_funzioni?.elenco_blocchi?._list[+k]?.testo?.['_value' + lang]
                                        }}>

                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            ) : null
                    }

                    <AppButton link={applicationState?.sezione_funzioni?.bottone?.link['_value' + lang]}
                               label={applicationState?.sezione_funzioni?.bottone?.etichetta?.['_value' + lang]}></AppButton>

                    <div className={'flex justify-center pt-32'}>
                        <p className={'font-s-36 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                           dangerouslySetInnerHTML={{__html: applicationState?.sezione_funzioni?.testo?.['_value' + lang]}}>

                        </p>
                    </div>

                </div>



            </div>


        </div>
    );
}

export default Application;

