export const goTo = (url: string, e?: any) => {
    if (url?.indexOf('http') == -1)
        window.location.href = url;
    else
        window.open(url, (url?.indexOf('http') >= 0 ? '_blank' : '#'));
    if (e)
        e.preventDefault();
}

export const mailTo = (url: string, e?: any) => {
    window.location.href = url;
    e.preventDefault();
}

export const getList = (items: any[] | undefined) => {
    return (items ? (Object.keys(items).filter((key: any) => items[key]._id != "0")
        .sort((a, b) => (items[a]?._order > items[b]?._order) ? 1 : -1).map(key => items[key])) : []);
}
