import { BrowserRouter, Route, Routes } from "react-router-dom";
import Scrollbar from 'smooth-scrollbar';
import AboutView from "./About/AboutView";
import EventsView from "./Events/EventsView";
import Footer from './Footer/Footer';
import Home from "./Home/Home";
import PrivacyView from "./Privacy/PrivacyView";
import Toolbar from "./Toolbar/Toolbar";
import Product from "./Product/Product";
import Service from "./Service/Service";
import Application from "./Application/Application";
import ScrollToTop from "./scroll-to-top/ScrollToTop";

const Main = () => {
    return (
        <BrowserRouter>
            <div
                data-aos="fade-in"
                data-aos-once="true">
                <Toolbar></Toolbar>

                 <ScrollToTop />

                <Routes>
                    <Route path='*' element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/prodotto" element={<Product />} />
                    <Route path="/servizi" element={<Service />} />
                    <Route path="/app" element={<Application />} />
                    <Route path="/about" element={<AboutView />} />
                    <Route path="/eventi" element={<EventsView />} />
                    <Route path="/privacy" element={<PrivacyView />} />
                </Routes>
                <Footer></Footer>
            </div>
        </BrowserRouter>

    );
};



export default Main;
