import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContext } from "./AppContext";
import './index.css';
import './assets/themes/theme.css';
import './assets/font-style.scss'
import {ParallaxProvider} from "react-scroll-parallax";





//Read url params and set global state
let search = window.location.search;
let params = new URLSearchParams(search);
const draft = params.get('draft') === "true";
const projectApiKey = params.get('projectApiKey');
const basePath = (draft ? 'drafts' : 'projects') + "/" + process.env.REACT_APP_STAGE;

export type Height = 'auto' | number | `${number}%`;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <AppContext.Provider value={{ appContext: { basePath: basePath, draft: draft, projectApiKey: projectApiKey } }}>
        <ParallaxProvider>
        <App />
        </ParallaxProvider>
    </AppContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

