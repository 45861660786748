import React, {useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import firebase from "../firebase";
import './Service.css';
import ScrollContainer from "react-indiana-drag-scroll";
import FiveItemsGallery from "../FiveItemsGallery/FiveItemsGallery";
import AppButton from "../AppButton/AppButton";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

const Service = () => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    const [serviceState, setServiceState] = useState<any>({});


    //Load data serviceState
    useEffect(() => {
        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/servizi');
        dbRef.on('value', snapshot => {
            console.log('Product', snapshot.val())
            let val = snapshot.val();
            setServiceState(val);
        });

    }, []);

    return (
        <div className={'bg-white'}>

            <div className={'pb-48'}>

                <div className={'flex justify-center pt-32'}>
                    <p className={'font-s-48 font-w-400 text-color-dark-grey max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: serviceState?.intro?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'mb-20 md:mb-40'}>
                    <AppButton link={serviceState?.intro?.bottone?.link['_value' + lang]}
                               label={serviceState?.intro?.bottone?.etichetta?.['_value' + lang]}></AppButton>
                </div>

                <FiveItemsGallery imageList={serviceState?.intro?.gallery?._list}></FiveItemsGallery>

            </div>

            {/*Servizi*/}
            <div className={'bg-dark-grey pb-48'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-dark-grey text-white h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {serviceState?.sezione_servizi?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex justify-center pt-32 pb-16'}>
                    <p className={'font-s-48 font-w-400 text-white max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: serviceState?.sezione_servizi?.testo?.['_value' + lang]}}>

                    </p>
                </div>

                <div className={'flex justify-center pb-16'}>
                    <p className={'font-s-20 font-w-400 text-white max-w-2xl text-center ml-12 mr-12'}
                       dangerouslySetInnerHTML={{__html: serviceState?.sezione_servizi?.testo_secondario?.['_value' + lang]}}>

                    </p>
                </div>

                <ScrollContainer className="app-slider">
                    {Object.keys(serviceState?.sezione_servizi?.elenco_blocchi?._list || {})
                        .filter((key: string) => serviceState?.sezione_servizi?.elenco_blocchi?._list[key as any]?._id != 0)
                        ?.map((k: string) => serviceState?.sezione_servizi?.elenco_blocchi?._list[k as any])
                        ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1).map((item: any, index: number) => (
                                <div className={'rectangle-div bg-white'} key={'service_' + index}>

                                    <div className={'flex justify-center'}>
                                        <img className={'h-16 md:h-20 w-16 md:w-20'}
                                             src={item?.immagine?.['_value']}/>
                                    </div>

                                    <div className={'font-s-24 font-w-600 flex justify-center text-center'}>
                                        {item?.titolo?.['_value' + lang]}
                                    </div>

                                    <div className={'font-s-16 font-w-400 flex justify-center text-center'}>
                                        {item?.testo?.['_value' + lang]}
                                    </div>


                                </div>
                            )
                        )
                    }
                </ScrollContainer>

                <AppButton link={serviceState?.sezione_servizi?.bottone?.link['_value' + lang]}
                           label={serviceState?.sezione_servizi?.bottone?.etichetta?.['_value' + lang]}
                ></AppButton>

            </div>

            <div className={'bg-white flex flex-col items-center pb-64'}>

                <div className={'flex justify-center relative bottom-8'}>
                    <p className={'bg-white text-color-dark-grey h-16 w-64 font-s-16 font-w-400 text-center flex items-center justify-center border-r-60 app-label'}>
                        {serviceState?.sezione_frequenze?.etichetta?.['_value' + lang]}
                    </p>
                </div>

                <div className={'flex items-center h-50-vmin w-full max-w-5xl mt-32 mb-32 pl-12 pr-12 md:pl-0 md:pr-0'}>
                    <div
                        data-aos="fade"
                        data-aos-once="true"
                        className={"relative event-item w-full h-full border-r-20"}
                        style={{
                            backgroundImage: 'url(' +
                                serviceState?.sezione_frequenze?.immagine?.['_value'] + ')',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}>
                    </div>
                </div>

                <div className={'flex flex-col items-center max-w-screen-lg pl-12 pr-12'}>

                    {
                        serviceState?.sezione_frequenze?.elenco_blocchi?._list ?
                            Object.keys(serviceState?.sezione_frequenze?.elenco_blocchi?._list)
                                .filter(k => k != "0").map(k =>
                                <Accordion key={'coll_' + k}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header">
                                        <p className={'font-s-36 font-w-600'}>
                                            {serviceState?.sezione_frequenze?.elenco_blocchi?._list[+k]?.titolo?.['_value' + lang]}
                                        </p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className={'font-s-20 font-w-400 pb-4'} dangerouslySetInnerHTML={{
                                            __html:
                                                serviceState?.sezione_frequenze?.elenco_blocchi?._list[+k]?.testo?.['_value' + lang]
                                        }}>

                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            ) : null
                    }

                    <AppButton link={serviceState?.sezione_frequenze?.bottone?.link['_value' + lang]}
                               label={serviceState?.sezione_frequenze?.bottone?.etichetta?.['_value' + lang]}
                    ></AppButton>

                </div>

            </div>


        </div>
    );
}

export default Service;

