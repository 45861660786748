import React, {FC, useContext, useEffect, useState} from "react";
import {AppContext, IApp} from "../AppContext";
import './AppButton.css';
import {goTo} from "../Utils";
import plus from "../assets/images/plus.svg";


const AppButton: FC<{ link: string, label: string }> = ({link, label}) => {

    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;
    const lang = ctx.languageCode;

    useEffect(() => {


    }, []);

    return (
        <div className={'flex justify-center mt-32'}>
            <div
                className={'bg-purple w-auto pl-6 gap-4 h-16 flex items-center border-r-60 cursor-pointer hover:opacity-75'}
                onClick={() => goTo(link)}>
                <p
                    className={'justify-end text-white font-s-16 font-w-400 uppercase'}>
                    {label}
                </p>
                <p className={'flex justify-end mr-2'}>
                    <img src={plus} style={{width: '48px', height: '48px'}}/>
                </p>
            </div>
        </div>
    );
}

export default AppButton;

