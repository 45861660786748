import React, {MutableRefObject, useContext, useEffect, useRef, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {AppContext, IApp} from "../AppContext";
import LanguagesView from "../Languages/LanguagesView";
import {getList, goTo} from "../Utils";
import firebase from "../firebase";
import './Toolbar.css';
import logo from "../assets/images/HSL_logo_orizzontale.png";

interface ToolbarState {
    mobileMenuOpened?: boolean;
    logo?: string;
    menuIcon?: string;
    phone?: string;
    menuInToolbar: MenuInToolbar[];
    pages: Page[];
    menus: any[];
}

interface MenuInToolbar {
    icon?: string;
    description: string;
    url: string;
    order:number;
}

interface Page {
    description: string;
    url: string;
}

const Toolbar = () => {
    const [toolbarState, setToolbarState] = useState<ToolbarState>({
        mobileMenuOpened: false,
        logo: '',
        menuInToolbar: [],
        menus: [],
        pages: []
    });

    const [socialState, setSocialState] = useState<any>(null);
    const appContext = useContext(AppContext);
    const ctx: IApp = appContext?.appContext;

    const divToolbarRef = useRef() as MutableRefObject<HTMLDivElement>;
    const divMobileMenuRef = useRef() as MutableRefObject<HTMLDivElement>;
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        const contentElement = divMobileMenuRef.current;
        if (isOpen) {
            contentElement.style.height = `${contentElement?.scrollHeight}px`;
        } else {
            contentElement.style.height = "0";
        }
    }, [isOpen]);


    useEffect(() => {
        firebase.database().ref(ctx.basePath + '/pagine').on('value', res => {
            const val = res.val();
            if (!val)
                return;
            const pagesLabels = Object.keys(val).filter((key: string) => val[key]._url && val[key]._visibility == true).sort((a, b) => (val[a]?._order >= val[b]?._order) ? 1 : -1);
            let pages: any[] = [];
            pagesLabels.forEach(menuLabel => {
                pages.push({
                    description: menuLabel?.replaceAll('_', ' '),
                    url: val[menuLabel]._url
                })
            })
            console.log('Page labels', pagesLabels)
            setToolbarState((prevState) => {
                return ({...prevState, pages: pages})
            });
        });

        firebase.database().ref(ctx.basePath + '/toolbar').on('value', res => {
            const val = res.val();

            setToolbarState((prevState) => {
                return ({
                    ...prevState,
                    logo: val?.logo?._value,
                    menuIcon: val?.icona_menu?._value,
                    menus: val?.menu._list?.sort((a, b) => (a?._order > b?._order) ? 1 : -1),
                    phone: val?.telefono?._value
                });
            });
        });

    }, []);

    //Insert menus and linked pages in toolbar
    useEffect(() => {
        setMenuInToolbar()
    }, [toolbarState.pages, toolbarState.menus]);

    const setMenuInToolbar = () => {
        if (toolbarState.menus?.length > 0 && toolbarState.pages?.length > 0) {
            toolbarState.menuInToolbar = [];
            for (let k of Object.keys(toolbarState.menus)) {
                //external link case
                if ((toolbarState.menus[k].link._value as string).startsWith('http')) {
                    toolbarState.menuInToolbar?.push(
                        {
                            description: toolbarState.menus[k].etichetta._value,
                            url: toolbarState.menus[k].link._value,
                            icon: toolbarState.menus[k].icona?._value,
                            order: toolbarState.menus[k]._order
                        }
                    )
                } else {
                    //internal page link case
                    let page = toolbarState.pages.find(p => p.description.toUpperCase() === toolbarState.menus[k].link._value.toUpperCase());
                    if (page) {
                        toolbarState.menuInToolbar?.push(
                            {
                                description: toolbarState.menus[k].etichetta._value,
                                url: page.url,
                                icon: toolbarState.menus[k].icona?._value,
                                order: toolbarState.menus[k]._order
                            }
                        )
                    }
                }
            }

            setToolbarState((prevState) => {
                return ({...prevState, menuInToolbar: toolbarState.menuInToolbar
                        .sort((a,b)=>a.order>=b.order?1:-1)})
            });
        }
    }

    const toggleMenu = () => {
        let isMenuOpened = toolbarState.mobileMenuOpened;

        window.scroll({top: 0});
        setTimeout(() => {
            setOpen(!isMenuOpened);
            setToolbarState((prevState) => {
                return ({
                    ...prevState,
                    mobileMenuOpened: !isMenuOpened
                });
            });
        }, 100);
    }

    return (<>
            <div ref={divToolbarRef}
                 className={'bkg-color-white flex w-full sticky top-0 items-center z-50 pl-4 pr-4 pt-2 pb-2 justify-between'}>
                <div className={'container-toolbar-logo'}>
                    <Link key='home-nav' to=''>
                        <img className="logo-img" src={logo}/>
                    </Link>
                </div>
                {/*menù desktop*/}
                <div className={'flex-1 justify-end items-center hidden md:flex'}>
                    {/*voci di menù*/}
                    {
                        toolbarState?.menuInToolbar?.map((menu, index) =>
                            <NavLink key={'menu' + index}
                                     className={({isActive}) => ('mr-8 capitalize title-extra-small menu-voice' + (isActive ? " text-color-dark-grey" : " color-mid-grey"))}
                                     to={menu.url}>
                                {menu.description}
                            </NavLink>
                        )
                    }
                    {/*lingue*/}
                    <div className={'ml-14'}>
                        <LanguagesView></LanguagesView>
                    </div>
                </div>
                {/*icona menù mobile*/}
                <div className={'flex md:hidden'}>
                    <span className="material-symbols-outlined text-color-mid-grey" onClick={() => toggleMenu()}>
                    menu
                    </span>
                </div>
            </div>
            {/*riquadro menù mobile*/}
            <div ref={divMobileMenuRef}
                 className={'flex flex-col sticky first-category-sticky-top overflow-hidden bs-transition-3s md:hidden z-50'}>
                <div className={'flex flex-col pt-6 pb-6 pr-4 pl-4 bkg-color-secondary'}>
                    {
                        toolbarState?.menuInToolbar?.map((menu, index) =>
                            <NavLink key={'menumobile-' + index}
                                     className={({isActive}) => 'title-medium mb-6 capitalize menu-voice ' + (isActive ? " text-color-dark-grey-mobile" : "")}
                                     onClick={() => toggleMenu()} to={menu.url}>
                                <div
                                    className={'bg-purple w-auto gap-4 h-8 flex items-center border-r-60 cursor-pointer hover:opacity-75'}>
                                    <p
                                        className={'justify-end text-white font-s-16 font-w-400 uppercase'}>
                                        {menu.description}
                                    </p>
                                </div>
                            </NavLink>
                        )
                    }

                </div>

                {/*lingue*/}
                <div className={'pt-3 pb-3 pr-4 pl-4 text-color-mid-grey'}>
                    <LanguagesView></LanguagesView>
                </div>
            </div>
        </>
    )

}

export default Toolbar;

